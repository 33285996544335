import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-layout-component',
  templateUrl: './public.component.html',
})
export class PublicComponent {
  //#region Properties

  public loggedUserFullName = '';

  //#endregion

}
