import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxLoggerLevel } from 'ngx-logger';
import { filter } from 'rxjs/operators';

import { AppSetting } from '@lbmx/types';
import { ConfigProvider } from './provider/config-provider';

import { AdminConfig, AdministrationService } from '@lbmx/administration';
import { MarketplaceService } from '@lbmx/marketplace';
import { MarketplacePartnersService } from '@lbmx/marketplace-partners';
import { MarketplacePartnersConfig } from '@lbmx/marketplace-partners/lib/marketplace-partners.config';
import { MarketplaceConfig } from '@lbmx/marketplace/lib/marketplace.config';
import { AuthConfig, AuthenticateService } from '@lbmx/phoenix-lib-auth';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import { PimConfig, PimService } from '@lbmx/phoenix-pim';
import { SiteType, SiteTypeService } from '@lbmx/root-services';

import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { Title } from '@angular/platform-browser';
import { CampaignPopupService } from './services/campaignPopup/campaignPopup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public subscriptions: Subscription[] = [];

  public siteNameClass: string;

  constructor(
    private configPrv: ConfigProvider,
    private authSrv: AuthenticateService,
    private adminSrv: AdministrationService,
    private loggerSrv: LoggerService,
    private pimSrv: PimService,
    private marketplacePartnersSrv: MarketplacePartnersService,
    private marketplaceSrv: MarketplaceService,
    // Required to initialize the breadcrumbs
    private breadcrumbService: BreadcrumbService,
    // Required to initialize the campaign popups
    private campaignPopupService: CampaignPopupService,
    public siteTypeService: SiteTypeService,
    private configProvider: ConfigProvider,
    private titleService: Title
  ) {
    this.configAuth();
    this.configAdmin();
    this.configPim();
    this.configMarketplacePartners();
    this.configMarketplace();
    this.configLogger();

    this.siteTypeService.setBaseUrl(
      this.configProvider.AppSetting?.uriConfiguration?.configurationRootURL
    );

    this.subscriptions.push(
      this.siteTypeService.siteType$.subscribe((siteType: SiteType) => {
        this.siteNameClass = this.siteTypeService.siteName;
        this.titleService.setTitle(
          (() => {
            switch (siteType) {
              case SiteType.MY_MARKETPLACE:
                return 'Trade';
              case SiteType.SUPPLY_CLOUD:
                return 'Supply Cloud';
              default:
                return 'LBMX';
            }
          })()
        );
      })
    );
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  private configLogger() {
    this.loggerSrv.setLoggerConfig(this.getUriLoggerConfig());
  }

  private getUriLoggerConfig() {
    const appSettings: AppSetting = this.configPrv.AppSetting;

    const loggerConfig = this.loggerSrv.getLoggerConfig();

    loggerConfig.level = NgxLoggerLevel.TRACE;
    loggerConfig.serverLoggingUrl = appSettings.uriLogging.logging;
    loggerConfig.serverLogLevel = NgxLoggerLevel.TRACE;

    return loggerConfig;
  }

  private configAuth() {
    this.authSrv.configure(this.getAuthConfig());

    this.subscriptions.push(
      this.authSrv.events
        .pipe(filter((e) => e.type === 'token_received'))
        .subscribe((e) => {
          this.loggerSrv.info(
            'AppComponent.configAuth',
            'User session has started: token_received'
          );
        })
    );

    this.configPim();
  }

  private getAuthConfig(): AuthConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;

    const authConfig: AuthConfig = {
      // Url of the Identity Provider
      // issuer: 'https://steyer-identity-server.azurewebsites.net/identity',

      // URL of the SPA to redirect the user to after login
      // redirectUri: window.location.origin + '/index.html',

      // URL of the SPA to redirect the user after silent refresh
      // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

      tokenEndpoint: appSettings.uriSecurity.authenticate,

      userinfoEndpoint: appSettings.uriSecurity.getProfile,

      refreshTokenEndpoint: appSettings.uriSecurity.slidingSession,

      logOutEndPoint: appSettings.uriSecurity.logOut,

      rememberMeLoadEndPoint: appSettings.uriSecurity.rememberMeLoad,

      getCookieRememberMeEndPoint: appSettings.uriSecurity.getCookieRememberMe,

      // The SPA's id. The SPA is registerd with this id at the auth-server
      // clientId: 'spa-phonenix',

      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      // scope: 'openid profile email voucher',
    };
    return authConfig;
  }

  private configPim() {
    this.pimSrv.setConfig(this.getPimConfig());
  }

  private getPimConfig(): PimConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    const pimConfig: PimConfig = {
      getAllProductFieldsEndpoint: appSettings.uriProduct.getAllProductFields,
      getFieldCategoriesEndpoint: appSettings.uriProduct.getFieldCategories,
      assetsEndPoint: appSettings.uriDocServer.assets,
      uploadAssetsEndPoint: appSettings.uriDocServer.uploadAssets,
      uploadSupplierAssetsEndPoint:
        appSettings.uriDocServer.uploadSupplierAssets,
      importFileEndPoint: appSettings.uriDocServer.imports,
      exportFileEndPoint: appSettings.uriDocServer.exports,
      getIncludedFieldsEndpoint: appSettings.uriProduct.getIncludedFields,
      getApprovedProductsEndpoint: appSettings.uriProduct.getApprovedProducts,
      searchApprovedProductsEndpoint:
        appSettings.uriProduct.searchApprovedProducts,
      getProductDetailsEndpoint: appSettings.uriProduct.productDetails,
      importLayoutsEndPoint: appSettings.uriImport.importLayouts,
      importEndPoint: appSettings.uriImport.import,
      getFileHeadersEndPoint: appSettings.uriImport.getFileHeaders,
      getFieldAllowedValuesEndpoint:
        appSettings.uriProduct.getFieldAllowedValues,
      getCountriesEndpoint: appSettings.uriProduct.getCountries,
      getCurrenciesEndpoint: appSettings.uriProduct.getCurrencies,
      getPartnersByType: appSettings.uriProduct.getPartnersByType,
      getPartnersByPermission: appSettings.uriProduct.getPartnersByPermission,
      account: appSettings.uriMarketplacePartners.account,
      exportProductsEndpoint: appSettings.uriProduct.exportProducts,
      updateProductsEndpoint: appSettings.uriProduct.updateProducts,
      searchExportsEndpoint: appSettings.uriDocServer.searchExports,
      upsertAssetsEndpoint: appSettings.uriProduct.upsertAssets,
      uriProduct: appSettings.uriProduct,
      searchPendingProductsEndpoint:
        appSettings.uriProduct.searchPendingProducts,
      approvePendingProductEndpoint: appSettings.uriProduct.approveProducts,
      rejectPendingProductEndpoint: appSettings.uriProduct.rejectProducts,
      getKpiEndPoint: appSettings.uriProduct.getKpi,
      getTermsAggregationEndPoint: appSettings.uriProduct.getTermsAggregation,
      getUsersFullNameEndPoint: appSettings.uriAdministration.getUserFullName,
      outboundProfileEndPoint: appSettings.uriProduct.outboundProfile,
      searchOutboundProfilesEndPoint:
        appSettings.uriProduct.searchOutboundProfiles,
      validateOutboundProfileNameEndPoint:
        appSettings.uriProduct.validateOutboundProfileName,
      getCategoryAggregationsEndPoint:
        appSettings.uriProduct.getCategoryAggregations,
      outboundProfileConfigurationsEndpoint:
        appSettings.uriProduct.outboundProfileConfigurations,
      auditProfileEndpoint: appSettings.uriProduct.auditProducts,
      getAutoApproveProducts: appSettings.uriProduct.getAutoApproveProducts,
      setAutoApproveProducts: appSettings.uriProduct.setAutoApproveProducts,
    };
    return pimConfig;
  }
  private configAdmin() {
    this.adminSrv.setConfig(this.getAdminConfig());
  }

  private getAdminConfig(): AdminConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    const adminConfig: AdminConfig = {
      accountAssetsEndPoint:
        appSettings.uriAdministration.accountAssetsEndPoint,
      searchUserEndpoint: appSettings.uriAdministration.searchUser,
      addUserEndpoint: appSettings.uriAdministration.addUser,
      copyUserEndpoint: appSettings.uriAdministration.copyUser,
      updateUserEndpoint: appSettings.uriAdministration.updateUser,
      listPermissionsEndpoint: appSettings.uriAdministration.listPermissions,
      listPermissionsEndpointV2:
        appSettings.uriAdministration.listPermissionsV2,
      updateUserPermissionsEndpoint:
        appSettings.uriAdministration.updateUserPermissions,
      deleteUserEndpoint: appSettings.uriAdministration.deleteUser,
      languageTypesEndpoint: appSettings.uriAdministration.languageTypes,
      timeZoneTypesEndpoint: appSettings.uriConfig.getTimeZoneTypes,
      dateFormatTypesEndpoint: appSettings.uriAdministration.dateFormatTypes,
      exportUsersEndpoint: appSettings.uriAdministration.exportUsers,
      getConfigEndpoint: appSettings.uriAdministration.getConfig,
      getAccountEndpoint: appSettings.uriAdministration.getAccount,
      getAccountRootURL: appSettings.uriMarketplacePartners.account,
      getConnectorEndpoint: appSettings.uriAdministration.getConnectorRootURL,
      getExportsEndpoint: appSettings.uriAdministration.getExportRootURL,
      taggingEndpoint: appSettings.uriAdministration.taggingEndpointRootURL,
      getConfiguration: appSettings.uriAdministration.getConfigurationRootURL,
      apiBaseUrl: appSettings.uriBase?.apiBaseUrl,
    };
    return adminConfig;
  }

  private configMarketplacePartners() {
    this.marketplacePartnersSrv.setConfig(this.getMarketplacePartnersConfig());
  }

  private getMarketplacePartnersConfig(): MarketplacePartnersConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    const marketplacePartnersConfig: MarketplacePartnersConfig = {
      getAccountRelationshipEndpoint:
        appSettings.uriMarketplacePartners.getAccountRelationship,
      getAccountEndpoint: appSettings.uriMarketplacePartners.getAccount,
      getConfigEndpoint: appSettings.uriMarketplacePartners.getConfig,
      getLocationEndpoint: appSettings.uriMarketplacePartners.getLocation,
      getUserStateEndpoint: appSettings.uriUserState.getUserState,
      getFieldCategoriesEndpoint: appSettings.uriProduct.getFieldCategories,
      getAllProductFieldsEndpoint: appSettings.uriProduct.getAllProductFields,
      accountAssetsEndPoint: appSettings.uriDocServer.uploadAccountAssets,
      deleteAccountAssetsEndPoint: appSettings.uriDocServer.deleteAccountAssets,
      updateAccountEndpoint: appSettings.uriMarketplacePartners.updateAccount,
      deleteLogoAssetKeyEndpoint:
        appSettings.uriMarketplacePartners.deleteLogoAssetKey,
      getDocumentServerEndpoint: appSettings.uriDocServer.imports,
      account: appSettings.uriMarketplacePartners.account,
      apiBaseUrl: appSettings.uriBase?.apiBaseUrl,
    };
    return marketplacePartnersConfig;
  }
  private configMarketplace() {
    this.marketplaceSrv.setConfig(this.getMarketplaceConfig());
  }

  private getMarketplaceConfig(): MarketplaceConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    const marketplaceConfig: MarketplaceConfig = {
      getDocumentServerEndpoint: appSettings.uriMarketplace.getDocumentServer,
      getDocumentEndpoint: appSettings.uriMarketplace.getDocument,
      getConfigEndpoint: appSettings.uriMarketplace.getConfig,
      getUserEndpoint: appSettings.uriMarketplace.getUser,
      sentInvoice: appSettings.uriMarketplace.sentInvoiceRootURL,
      sentPo: appSettings.uriMarketplace.sentPoRootURL,
      sentPoa: appSettings.uriMarketplace.sentPoaRootURL,
      sentAsn: appSettings.uriMarketplace.sentAsnRootURL,
      sentRemittances: appSettings.uriMarketplace.sentRemittancesRootURL,
      sentWarehouse: appSettings.uriMarketplace.sentWarehouseRootURL,
      receivedTM: appSettings.uriMarketplace.receivedTMRootURL,
      receivedAA: appSettings.uriMarketplace.receivedAARootURL,
      sentPad: appSettings.uriMarketplace.sentPadRootURL,
      sentPi: appSettings.uriMarketplace.sentPiRootURL,
      sentOs: appSettings.uriMarketplace.sentOsRootURL,
      sentDsdsi: appSettings.uriMarketplace.sentDsdsiRootURL,
      sentPsrc: appSettings.uriMarketplace.sentPsrcRootURL,
      sentCda: appSettings.uriMarketplace.sentCdaRootURL,
      sentPoc: appSettings.uriMarketplace.sentPocRootURL,
      receivedInvoice: appSettings.uriMarketplace.receivedInvoiceRootURL,
      receivedCda: appSettings.uriMarketplace.receivedCDARootURL,
      receivedOs: appSettings.uriMarketplace.receivedOSRootURL,
      receivedDsdsi: appSettings.uriMarketplace.receivedDsdsiRootURL,
      receivedPo: appSettings.uriMarketplace.receivedPoRootURL,
      receivedPi: appSettings.uriMarketplace.receivedPiRootURL,
      receivedPsrc: appSettings.uriMarketplace.receivedPsrcRootURL,
      receivedPad: appSettings.uriMarketplace.receivedPadRootURL,
      receivedStatement: appSettings.uriMarketplace.receivedStatementRootURL,
      receivedWarehouse: appSettings.uriMarketplace.receivedWarehouseRootURL,
      receivedPoc: appSettings.uriMarketplace.receivedPOCRootURL,
      receivedRebateStatement:
        appSettings.uriMarketplace.receivedRebateStatementRootURL,
      receivedPoa: appSettings.uriMarketplace.receivedPoaRootURL,
      receivedAsn: appSettings.uriMarketplace.receivedAsnRootURL,
      receivedRemittances:
        appSettings.uriMarketplace.receivedRemittancesRootURL,
      connectors: appSettings.uriAdministration.getConnectorRootURL,
      uriUserState: appSettings.uriUserState,
      apiBaseUrl: appSettings.uriBase?.apiBaseUrl,
      export: appSettings.uriAdministration.getExportRootURL,
      connectorEndpoint: appSettings.uriAdministration.getConnectorRootURL,
    };
    return marketplaceConfig;
  }

  //#endregion
}
