export class User {
  public username: string;

  public password: string;

  public fullname: string;

  public languageCode: string;

  public accountKey: number;

  public addedOn: Date;
}

export enum NotificationTypes {
  POA = 1,
  PO = 2,
  Invoice = 3,
  ASN = 4,
  RemittancesReceived = 5,
  SentPO = 6,
  SentPOWarning = 7,
  BankingAlert = 8,
  CrossReferenceImport = 9,
  CollectionBatch = 10,
  PaymentBatchRejected = 11,
  Export = 12,
  SentTM = 13,
  SentPOC = 14,
  SentOS = 15,
  ReceivedPAD = 16,
  PaymentBatchAccepted = 17,
  InvoiceKeptOnHold = 18,
  OS = 19,
  POC = 20,
  AA = 21,
  TM = 22,
  CDA = 23,
  STATEMENT = 24,
  REBATESTATEMENT = 25,
  ProductsOutdated = 26,
  InvoiceMissing = 27,
  DSDSI = 28,
  PSRC = 29,
  PI = 30,
  WSO = 31,
  WSTSA = 32,
  WSTRA = 33,
  WSA = 34,
  WIAA = 35,
}
export interface IUserNotificationResponse {
  userNotificationList: IUserNotification[];
}
export interface IUserNotification {
  userNotificationKey?: number;
  userKey: number;
  notificationTypeKey: number;
  isActive: boolean;
  interval: number;
  addedOn: Date;
  addedBy: number;
  modifiedOn?: Date;
  modifiedBy?: number;
  label?: string;
  checkboxCtl?: string;
  intervalCtl?: string;
}

export interface INotificationRequest {
  UserNotificationKey?: number;
  UserKey: number;
  NotificationTypeKey: number;
  IsActive: boolean;
  Interval: number;
  AddedOn: Date;
  AddedBy: number;
  ModifiedOn?: Date;
  ModifiedBy?: number;
}
