import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { actions, selectors } from '@lbmx/app-state';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  tap,
} from 'rxjs/operators';

import { Marketplace } from '@lbmx/types';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { SecureService } from './secure.service';

@Component({
  selector: 'app-marketplace-modal',
  template: `<p-dialog
    [(visible)]="showModal"
    [showHeader]="false"
    [modal]="true"
    position="center"
    [draggable]="false"
    [resizable]="false"
    [responsive]="true"
    [closeOnEscape]="false"
    styleClass="marketplace-selector-modal"
    data-testid="marketplace-selector-modal"
  >
    <div class=" vertical-flow marketplace-selector">
      <h2 class="center">{{ 'COMMON.MY_MARKETPLACES' | transloco }}</h2>
      <div class="center space-before-000">
        {{ 'COMMON.MARKETPLACE_SELECTION_INFO' | transloco }}
      </div>
      <div
        *ngFor="let marketplace of marketplaces; let i = index"
        [id]="'marketplace_' + (i + 1)"
        class="vertical-flow space-between-150 raised clickable"
        [ngClass]="i == 0 ? 'space-before-025 ' : 'space-before-100'"
        (click)="setMarketplace(marketplace.accountKey.toString())"
      >
        <div class="horizontal-flow">
          <ng-container *ngIf="marketplace.logo; else logoMissing">
            <div
              class="img-container marketplace-logo"
              [id]="'marketplace_logo_' + (i + 1)"
            >
              <img [src]="marketplace.logo" class="target" /></div
          ></ng-container>
          <ng-template #logoMissing>
            <div
              class="img-container marketplace-logo no-img"
              [id]="'marketplace_no_logo_' + (i + 1)"
            >
              <span>
                {{ 'COMPANY.LOGO_DEFAULT_MSG_1' | transloco }}<br />{{
                  'COMPANY.LOGO_DEFAULT_MSG_2' | transloco
                }}
              </span>
            </div>
          </ng-template>
          <div
            class="vertical-flow space-between-000 overflow-wrap"
            [id]="'marketplace_details_' + (i + 1)"
          >
            <div *ngIf="marketplace.legalName" class="name">
              {{ marketplace.legalName }}
            </div>
            <div *ngIf="marketplace.dbaName">{{ marketplace.dbaName }}</div>
            <div *ngIf="marketplace.address1">{{ marketplace.address1 }}</div>
            <div *ngIf="marketplace.address2">{{ marketplace.address2 }}</div>
            <div *ngIf="marketplace.city || marketplace.provinceName">
              {{ marketplace.city }}
              <span *ngIf="marketplace.provinceName">{{
                marketplace.provinceName
              }}</span>
            </div>
            <div *ngIf="marketplace.postalCode">
              {{ marketplace.postalCode }}
            </div>
            <div *ngIf="marketplace.countryName">
              {{ marketplace.countryName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>`,
})
export class MarketplaceModalComponent implements OnInit, OnDestroy {
  public marketplaces: string | Marketplace[] = [];
  public showModal: boolean;
  public subscriptions: Subscription[] = [];
  private lastCall: number = Date.now();

  @Input('showMarketplaceModal') set showMarketplaceModalSetter(time: number) {
    if (time || 0 > this.lastCall) {
      this.setMarketplace('');
    }
    this.lastCall = time;
  }

  constructor(
    private configPrv: ConfigProvider,
    private router: Router,
    private store: Store,
    private translocoService: TranslocoService,
    private secureService: SecureService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .pipe(select(selectors.marketplaces))
        .subscribe(({ marketplace, marketplaces }) => {
          const count = marketplaces.length;
          // More than one marketplace
          this.showModal = marketplace === '' && count > 1;
          this.secureService.setSelectedMarketplace(
            !this.showModal && marketplace !== ''
          );
          this.marketplaces = marketplaces;
          // No marketplace
          if (count === 0) {
            this.redirectToErrorPage();
          }
          // Only 1 marketplace
          if (
            marketplace === '' &&
            count === 1 &&
            marketplaces instanceof Array
          ) {
            this.setMarketplace(`${marketplaces[0].accountKey}`);
          }
        }),
      this.store
        .pipe(
          select(selectors.marketplaces),
          map(({ marketplace }) => marketplace),
          filter((marketplace) => marketplace !== ''),
          distinctUntilChanged(),
          tap(() => {
            const currentUrl = this.router.url;

            this.router
              .navigateByUrl('/home', { skipLocationChange: true })
              .then(() => {
                this.router.navigateByUrl(currentUrl);
              });
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy() {
    this.secureService.setSelectedMarketplace(false);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private redirectToErrorPage() {
    this.subscriptions.push(
      this.translocoService
        .selectTranslate('COMMON')
        .pipe(
          tap((translate) => {
            this.router.navigate(['/error'], {
              state: {
                title: translate.MARKETPLACE_ERROR,
                message: translate.MARKETPLACE_CONFIG_INFO,
              },
            });
          })
        )
        .subscribe()
    );
  }

  public setMarketplace(marketplace: string) {
    this.store.dispatch(
      actions.updateUserProfileMarketplace({
        marketplace,
        endPoints: this.configPrv.AppSetting,
      })
    );

    // if (marketplace !== '') {
    //   this.secureService.marketplaceSwitched(this.router.url);
    // }
  }
}
