import { ColumnDefinitions, SearchFilters } from '@lbmx/analytics';
import { FieldsDefinition, Form } from '@lbmx/per-form';
import { ColumnsConfig } from '../report-loader/report-loader.ui';

// export enum TableAlignment {
//   LEFT = 'left',
//   CENTER = 'center',
//   RIGHT = 'right',
// }

// export enum FilterType {
//   FILTER = 'filter',
//   SORT_FIELD = 'sortField',
//   SORT_ORDER = 'sortOrder',
//   VARIANCE = 'variance',
// }

// export interface ColumnsConfig {
//   valueField: string;
//   label: string;
//   alignment: TableAlignment;
// }

export interface ReportBuilderReportConfig {
  fields: any;
  record: {
    endpoint: string;
    // label: string;
    // filters: FieldsDefinition<Form>;
    defaultQuery: SearchFilters;
    columns: ColumnsConfig[];
    // totalRowQuery: SearchFilters;
  };
}

export interface QueryResponse {
  records: Array<{ [key: string]: any }>;
  totalRecords: number;
}

export interface SingleQueryResponse {
  record: ReportTemplate;
  totalRecords: number;
}

// export interface Footer {
//   [key: string]: any;
// }

// export const buildFilters;
export interface DataSource {
  name: string;
  code: string;
}

export interface DropdownFilterOptions {
  // Define the properties and methods of DropdownFilterOptions here
  // Example:
  reset(): void;
  filter(event: KeyboardEvent): void;
}

export class ReportTemplate {
  public reportName?: string;
  public description?: string;
  public dataSource?: string;
  public jsonconfig?: string;
  public endPoint?: string;
  public reportFormat?: string;
  public reportOptions?: string;
  public reportKey?: string;
}

export interface ExportParams {
  type: string;
  config: {
    filename: string;
    header?: string;
  };
}
