import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecureService {
  private marketplaceSelected: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public marketplaceSelected$: Observable<boolean> =
    this.marketplaceSelected.asObservable();

  private navItems: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public navItems$: Observable<boolean> = this.navItems.asObservable();

  public isReady$: Observable<boolean> = combineLatest([
    this.navItems$,
    this.marketplaceSelected$,
  ]).pipe(
    map((flags) => [...flags].reduce((isReady, flag) => isReady && flag, true))
  );

  constructor(private router: Router) {}
  public setSelectedMarketplace(isSet: boolean) {
    this.marketplaceSelected.next(isSet);
  }

  public setNavItems(isSet: boolean) {
    this.navItems.next(isSet);
  }
}
