import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { actions, selectors } from '@lbmx/app-state';
import { BaseHttpService, SearchService } from '@lbmx/root-services';
import { NavItem, UserProfile } from '@lbmx/types';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng-lts';
import { combineLatest, interval, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { OtpComponent } from '../../features/secure/otp/otp.component';

import { Actions, ofType } from '@ngrx/effects';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { NavBarService } from 'src/app/services/navbar/nav-bar.service';
import {
  SiteType,
  SiteTypeService,
} from 'src/app/services/siteType/site-type.service';
import { OtpService } from '../../services/otp/otp.service';
import { SecureService } from './secure.service';
import { sessionExpired } from './utils';

@Component({
  selector: 'app-secure-layout-component',
  templateUrl: './secure.component.html',
  providers: [DialogService, SearchService, NavBarService],
})
export class SecureComponent implements OnInit, AfterContentChecked, OnDestroy {
  //#region Properties
  private shouldDestroy = new Subject<boolean>();
  private sessionTimer = interval(1000);
  private sessionExpires: Date;
  private dynamicDialogRef: DynamicDialogRef = null;
  public loggedUserFullName = '';
  public ready = false;
  public showMarketplaceModal: number;
  public showPortalModal: number;
  public subscriptions: Subscription[] = [];
  public navItems: NavItem[] = [];
  private navItems$ = this.store.pipe(
    select(selectors.navItems),
    tap((navItems) => {
      this.navItems = navItems;
      this.secureService.setNavItems(true);
    }),
    takeUntil(this.shouldDestroy)
  );
  //#endregion

  constructor(
    private analyticsService: AnalyticsService,
    private baseHttpService: BaseHttpService,
    private configPrv: ConfigProvider,
    private store: Store,
    private actions$: Actions,
    private translocoService: TranslocoService,
    private router: Router,
    public secureService: SecureService,
    private changeDetectorRef: ChangeDetectorRef,
    private otpService: OtpService,
    private dialogService: DialogService,
    public navbarService: NavBarService,
    public siteTypeService: SiteTypeService
  ) {
    this.configStore();
    this.configureOtp();
  }

  @HostListener('mouseenter')
  public onMouseEnter() {
    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.userProfile),
          map((user: UserProfile) => ({
            ...user.userInfo,
            MarketplaceSiteType:
              user.userInfo.MarketplaceKey === 0
                ? 0
                : user.userInfo.MarketplaceSiteType,
          })),
          filter((userInfo) => this.isUserMarketplaceValid(userInfo)),
          tap(() =>
            this.store.dispatch(
              actions.refreshUserProfile({
                endPoints: this.configPrv.AppSetting,
              })
            )
          )
        )
        .subscribe()
    );
  }

  private configureOtp() {
    this.subscriptions.push(
      this.otpService.showDialog$.subscribe((showOtp) => {
        if (showOtp) {
          this.openOtp();
        }
      })
    );
  }

  private openOtp() {
    this.dynamicDialogRef = this.dialogService.open(OtpComponent, {
      showHeader: false,
      closeOnEscape: true,
    });

    this.dynamicDialogRef.onClose
      .pipe(
        tap((values) => {
          this.otpService.submitWithOTP(values);
        })
      )
      .subscribe();
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.session),
          tap(
            (session) =>
              (this.sessionExpires = new Date(session.sessionExpires))
          )
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.sessionTimer
        .pipe(map(() => sessionExpired(this.sessionExpires)))
        .subscribe((expired) => {
          if (expired) {
            this.router.navigate(['login'], { queryParams: { reAuth: true } });
          }
        })
    );

    this.subscriptions.push(
      combineLatest([this.store.pipe(select(selectors.marketplaces))])
        .pipe(
          filter(([marketplaces]) => marketplaces.marketplaces !== null),
          tap(() => (this.ready = true))
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(actions.updateUser),
          withLatestFrom(this.store.select(selectors.userProfile)),
          tap(([, userProfile]) => {
            this.store.dispatch(
              actions.updateUserProfile({
                userProfile,
                endPoints: this.configPrv.AppSetting,
              })
            );
          })
        )
        .subscribe()
    );

    this.navItems$.subscribe();
  }
  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  public refreshUserProfile() {
    return () =>
      this.store.dispatch(
        actions.refreshUserProfile({
          endPoints: this.configPrv.AppSetting,
        })
      );
  }

  public ngOnDestroy() {
    if (this.dynamicDialogRef) {
      this.otpService.request = null;
      this.dynamicDialogRef.destroy();
    }

    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    this.shouldDestroy.next(true);
  }

  private configStore() {
    const { uriProduct, uriAnalytics, uriUserState, uriAdministration } =
      this.configPrv.AppSetting;

    this.store.dispatch(
      actions.loadAnalyticsConfig({ endPoints: uriAnalytics })
    );
    this.store.dispatch(
      actions.loadUserProfile({ endPoints: this.configPrv.AppSetting })
    );
    this.store.dispatch(actions.loadSettings({ endPoints: uriUserState }));
    this.store.dispatch(
      actions.getMarketplaces({ endPoints: this.configPrv.AppSetting })
    );

    this.store.dispatch(
      actions.setNavItems({ endPoints: this.configPrv.AppSetting })
    );

    this.store.dispatch(actions.loadCategories({ endPoints: uriProduct }));
    this.store.dispatch(actions.loadProductFields({ endPoints: uriProduct }));
    this.store.dispatch(actions.loadUsers({ endPoints: uriAdministration }));
  }

  private isUserMarketplaceValid(userInfo: {
    MarketplaceKey: number;
    MarketplaceSiteType: number;
  }) {
    enum MP_KEY {
      TEST = 4,
      QA = 2199,
      STAGE_PROD = 40,
    }
    const key: number = (() => {
      switch (window.location.hostname) {
        case 'localhost':
        case 'marketplace.phoenix.test.lbmx.com':
        case 'supplycloud.phoenix.test.lbmx.com':
          return MP_KEY.TEST;
        case 'marketplace.phoenix.qa.lbmx.com':
        case 'supplycloud.phoenix.qa.lbmx.com':
          return MP_KEY.QA;
        default:
          return MP_KEY.STAGE_PROD;
      }
    })();
    return (
      (this.siteTypeService.siteType === SiteType.MY_MARKETPLACE &&
        userInfo.MarketplaceKey === key) ||
      (this.siteTypeService.siteType === SiteType.SUPPLY_CLOUD &&
        userInfo.MarketplaceKey !== key) ||
      this.siteTypeService.siteType !== userInfo.MarketplaceSiteType
    );
  }
}
