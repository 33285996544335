import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Form, PerForm } from '@lbmx/per-form';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';
import { AppSetting } from '@lbmx/types';
import { TranslocoService } from '@ngneat/transloco';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import {
  ReportBuilderReportConfig,
  ReportTemplate,
  SingleQueryResponse,
} from '../flexmonster-loader/flexmonster-loader.ui';

@Component({
  selector: 'app-flexmonster',
  templateUrl: './flexmonster.component.html',
  styleUrls: ['./flexmonster.component.scss'],
})
export class FlexmonsterComponent implements OnInit, OnDestroy {
  public appSettings: AppSetting = this.configPrv.AppSetting;
  public reportEndpoint = '';
  public form: PerForm;
  public params: Form;
  private _onDestroySubject = new Subject();
  public reportRequest: Observable<any>;
  public response: any;
  public groupedDataSources: SelectItemGroup[];
  public filteredDataSources: any[];
  public recordData: object[];
  public selectedReportTemp: ReportTemplate;
  public yearOptions: SelectItem[] = [];
  public selectedYear: number;
  public selectedDataSource: any;
  public rangeDates: Date[] | null = null;
  public isDateAndReportSelected = false;
  public es: {};
  public minDate: Date;
  public maxDate: Date;
  public invalidDates: Date[];
  public dateRangeSelected = false;

  constructor(
    private configPrv: ConfigProvider,
    public httpClient: HttpClient,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private toast: ToastrNotificationService,
    private translocoService: TranslocoService
  ) {}

  public onDataSourceChange(event: any) {
    const selectedItem = this.getItemByValue(
      event.value,
      event.originalEvent.srcElement.innerText
    );
    this.selectedDataSource = selectedItem;
    this.handleDateChange();

    this.http
      .post<SingleQueryResponse>(
        this.configPrv.AppSetting.uriAnalytics.queryEndpoint +
          '/SearchByReportKey' || '',
        {
          ...selectedItem,
        },
        { withCredentials: true }
      )
      .subscribe((response) => {
        this.selectedReportTemp = { ...response.record };
      });
  }

  private initializeYearOptions() {
    const currentYear = new Date().getFullYear();
    this.yearOptions = [];
    for (let i = 0; i < 3; i++) {
      this.yearOptions.push({
        label: `${currentYear - i}`,
        value: currentYear - i,
      });
    }
  }

  private handleDateChange() {
    if (this.rangeDates && this.rangeDates.length === 2) {
      const [startDate, endDate] = this.rangeDates;
      // If both dates are valid, show the pivot table
      if (startDate && endDate) {
        this.rangeDates = [...this.rangeDates]; // trigger onchanges in loader
        this.isDateAndReportSelected = true;
        // this.fetchPivotTableData(startDate, endDate);
      } else {
        this.isDateAndReportSelected = false;
        // this.toast.errorNotify('Please select a valid date range.');
      }
    } else {
      // If the range is incomplete (only one date is selected)
      this.isDateAndReportSelected = false;
      // this.toast.errorNotify('Please select a valid date range.');
    }
  }

  // Handle the Year Selection
  public onDateChange(event: any) {
    this.handleDateChange();
  }
  // public fetchPivotTableData(startDate: Date, endDate: Date) {
  //   throw new Error('Method not implemented.');
  //   // console.log('Fetching pivot table data...');
  //   // console.log(startDate, 'startDate');
  //   // console.log(endDate, 'startDaendDatete');
  // }

  public getItemByValue(value: string, label: string) {
    for (const group of this.groupedDataSources) {
      const item = group.items.find(
        (i) => i.value === value && i.label === label
      );
      if (item) {
        const report = {
          reportId: item.value,
          reportName: item.label,
          isGenerated: item['isGenerated'],
        };
        return report;
      }
    }
    return null;
  }

  public OnReportSaved(isSaved: boolean) {
    if (isSaved) {
      setTimeout(() => {
        this.PopulateDataSourceDropDown();
        this.cdr.detectChanges();
        this.toast.successNotify(
          this.translocoService.translate('REPORTBUILDER.TOAST_REPORT_SAVED')
        );
      }, 400);
    }
  }
  public PopulateDataSourceDropDown() {
    this.http
      .get<ReportBuilderReportConfig>(
        this.configPrv.AppSetting.uriAnalytics.getReportBuilderDashboardConfig,
        { withCredentials: true }
      )
      .pipe(
        tap((response) => {
          this.groupedDataSources = [...response.fields.report.options];
          this.groupedDataSources = [...this.groupedDataSources];
        })
      )
      .subscribe();
  }
  public ngOnInit(): void {
    this.initializeYearOptions();
    this.PopulateDataSourceDropDown();
    this.es = {
      firstDayOfWeek: 1,
      dayNames: [
        'domingo',
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado',
      ],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
      monthNamesShort: [
        'ene',
        'feb',
        'mar',
        'abr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dic',
      ],
      today: 'Hoy',
      clear: 'Borrar',
    };
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = prevMonth === 11 ? year - 1 : year;
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextYear = nextMonth === 0 ? year + 1 : year;
    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);

    this.maxDate = today; // Set maxDate to today's date
    this.minDate = new Date();
    this.minDate.setFullYear(today.getFullYear() - 3); // Set minDate to 3 years before today

    // Invalid dates (optional logic)
    const invalidDate = new Date();
    invalidDate.setDate(today.getDate() - 1);
    this.invalidDates = [today, invalidDate];
  }

  public ngOnDestroy(): void {
    this._onDestroySubject.next(true);
  }
}
