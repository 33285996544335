import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteType } from '@lbmx/root-services';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfigProvider } from '../../../provider/config-provider';
import { SiteTypeService } from '../../../services/siteType/site-type.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit, OnDestroy {
  //region Properties

  public lbmxLogoImage = `./assets/images/powered-by-lbmx.svg`;
  public lbmxUrl: string;
  public thisYear = new Date().getFullYear();
  public terms = '';

  public isSupplyCloudLogin = false;

  private _shouldDestroy = new Subject();

  //#endregion

  constructor(
    private router: Router,
    private configProvider: ConfigProvider,
    private siteTypeService: SiteTypeService
  ) {
    this.siteTypeService.siteType$
      .pipe(
        tap(() => {
          this.lbmxUrl = this.siteTypeService.homeUrl;

          if (
            this.router.url.includes('/login') ||
            this.router.url.includes('/logout')
          ) {
            switch (this.siteTypeService.siteType) {
              case SiteType.MY_MARKETPLACE:
                this.lbmxLogoImage = `./assets/images/lbmx_logo.png`;
                break;
              case SiteType.SUPPLY_CLOUD:
                this.isSupplyCloudLogin = true;
                this.lbmxLogoImage = `${this.configProvider.AppSetting?.uriConfiguration?.configurationRootURL}/logo`;
                break;
            }
          }
        }),
        takeUntil(this._shouldDestroy)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._shouldDestroy.next(true);
  }

  public ngOnInit() {
    /** empty block */
  }
}
