import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { actions } from '@lbmx/app-state';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Account } from '@lbmx/types';
import { Actions, ofType } from '@ngrx/effects';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { SecureService } from './secure.service';

@Component({
  selector: 'app-account-modal',
  template: `<p-dialog
    [(visible)]="showModal"
    [showHeader]="false"
    [modal]="true"
    position="center"
    [draggable]="false"
    [resizable]="false"
    [responsive]="true"
    [closeOnEscape]="false"
    styleClass="marketplace-selector-modal"
    data-testid="marketplace-selector-modal"
  >
    <div class=" vertical-flow marketplace-selector">
      <h2 class="center">{{ 'COMMON.MY_ACCOUNTS' | transloco }}</h2>
      <div class="center space-before-000">
        {{ 'COMMON.ACCOUNT_SELECTION_INFO' | transloco }}
      </div>
      <div
        *ngFor="let account of accounts; let i = index"
        [id]="'marketplace_' + (i + 1)"
        class="vertical-flow space-between-150 raised clickable"
        [ngClass]="i == 0 ? 'space-before-025 ' : 'space-before-100'"
        (click)="setAccount(account.accountKey.toString())"
      >
        <div class="horizontal-flow">
          <ng-container *ngIf="account.logo; else logoMissing">
            <div
              class="img-container marketplace-logo"
              [id]="'marketplace_logo_' + (i + 1)"
            >
              <img [src]="account.logo" class="target" /></div
          ></ng-container>
          <ng-template #logoMissing>
            <div
              class="img-container marketplace-logo no-img"
              [id]="'marketplace_no_logo_' + (i + 1)"
            >
              <span>
                {{ 'COMPANY.LOGO_DEFAULT_MSG_1' | transloco }}<br />{{
                  'COMPANY.LOGO_DEFAULT_MSG_2' | transloco
                }}
              </span>
            </div>
          </ng-template>
          <div
            class="vertical-flow space-between-000 overflow-wrap"
            [id]="'marketplace_details_' + (i + 1)"
          >
            <div *ngIf="account.legalName" class="name">
              {{ account.legalName }}
            </div>
            <div *ngIf="account.dbaName">{{ account.dbaName }}</div>
            <div *ngIf="account.address1">{{ account.address1 }}</div>
            <div *ngIf="account.address2">{{ account.address2 }}</div>
            <div *ngIf="account.city || account.province">
              {{ account.city }}
              <span *ngIf="account.provinceName">{{
                account.provinceName
              }}</span>
            </div>
            <div *ngIf="account.postalCode">
              {{ account.postalCode }}
            </div>
            <div *ngIf="account.countryName">
              {{ account.countryName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>`,
})
export class AccountModalComponent implements OnInit, OnDestroy {
  public accounts: Account[] = [];
  public showModal: boolean;
  public subscriptions: Subscription[] = [];

  @Input('showAccountModal') set showAccountModalSetter(state: boolean) {
    if (state) {
      this.store.dispatch(
        actions.getAccounts({
          endPoints: this.configPrv.AppSetting,
        })
      );
    } else {
      this.showModal = false;
    }
  }

  constructor(
    private configPrv: ConfigProvider,
    private router: Router,
    private store: Store,
    private translocoService: TranslocoService,
    private secureService: SecureService,
    private actions$: Actions
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(actions.getAccountsSuccess),
          tap(({ accounts }) => {
            const count = accounts.length;
            // More than one account
            if (count > 1) {
              this.secureService.setSelectedMarketplace(false);
              this.showModal = true;
              this.accounts = accounts;
            } else if (count === 0) {
              this.redirectToErrorPage();
            }
          })
        )
        .subscribe()
    );
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(actions.setAccountSuccess),
          tap(() => {
            this.showModal = false;
            this.secureService.setSelectedMarketplace(true);
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private redirectToErrorPage() {
    this.showModal = false;
    this.subscriptions.push(
      this.translocoService
        .selectTranslate('COMMON')
        .pipe(
          tap((translate) => {
            this.router.navigate(['/error'], {
              state: {
                title: translate.ACCOUNT_SELECTION_ERROR,
                message: translate.ACCOUNT_CONFIG_INFO,
              },
            });
          })
        )
        .subscribe()
    );
  }

  public setAccount(accountKey: number) {
    this.store.dispatch(
      actions.setAccount({
        accountKey,
        endPoints: this.configPrv.AppSetting,
      })
    );
  }
}
